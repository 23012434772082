import React, { useEffect } from "react";
import { useLottie } from "lottie-react";
import icons from "../utils/animations";

type TProps = {
  icon: string;
  color?: string;
  action?: "click" | "hover" | "loop";
  speed?: number;
  stopAt?: number;
  isActive?: boolean;
  onClick?: () => void;
  containerStyle?: React.CSSProperties;
};

export default function AnimatedIcon({
  icon,
  color,
  action,
  speed,
  stopAt,
  isActive,
  onClick,
  containerStyle,
}: TProps) {
  const lottieOptions = {
    animationData: icons[icon],
    autoplay: action === "loop",
    loop: action === "loop",
    style: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
  };

  const { View, play, setSpeed, setDirection, goToAndStop, stop } =
    useLottie(lottieOptions);

  useEffect(() => {
    if (speed) {
      setSpeed(speed);
    }
  }, [speed]);

  useEffect(() => {
    if (stopAt) {
      play();
      setTimeout(() => {
        goToAndStop(stopAt);
      }, stopAt);
    }
  }, [stopAt]);

  const onIconClick = () => {
    setDirection(isActive ? -1 : 1);
    play();

    if (onClick) {
      onClick();
    }
  };

  const onMouseEnter = () => {
    setDirection(1);
    play();
  };

  const onMouseLeave = () => {
    setDirection(-1);
    play();
  };

  return (
    <div
      className="w-full h-full"
      onClick={action === "click" ? onIconClick : null}
      onMouseEnter={action === "hover" ? onMouseEnter : null}
      onMouseLeave={action === "hover" ? onMouseLeave : null}
      style={{ ...containerStyle }}
      color={color}
    >
      {View}
    </div>
  );
}
