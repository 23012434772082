import price from "../public/animations/price.json";
import crash from "../public/animations/crash.json";
import warning from "../public/animations/warning.json";
import verified from "../public/animations/verified.json";

const ANIMATIONS: Record<string, unknown> = {
  price,
  crash,
  warning,
  verified,
};

export default ANIMATIONS;
